$(document).ready(function() {
    /* Select active menu */
    var adress = window.location.pathname;
    first =adress.split('/');
    first = first[1];
    if(adress == '/') {
        $( ".test" ).first().addClass('activeMenu');
    } else {
        $( ".navbar-nav > li" ).each(function( index ) {
            if($(this).html().indexOf(adress) > 0) {
                $( ".navbar-nav .activeMenu" ).removeClass('activeMenu');
                $(this).addClass('activeMenu');
                if ($(this).hasClass('dropdown-submenu')) {
                    console.log(this);
                    $(this).find('ul li').each(function( index ) {
                        if($(this).html().indexOf(adress) > 0) {
                            $(this).addClass('activeMenu');
                        }
                    });
                }

                first = null;
                return;
            } else if($(this).html().indexOf(first) > 0) {
                $(this).addClass('activeMenu');
            } 
        });
    } 
});
